@import '~antd/dist/reset.css';

.code-row {
  padding: 0;
  font-family: 'courier';
  background-color: white;
  white-space: pre;
  width: 100%;
}

.container {
  border: 1px solid #73AD21;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50px;
}

.line:hover .plus-square-line {
  opacity: 1;
}

.line-number {
  user-select: none;
  background-color: #d5e1df;
  text-align: center;
  width: 5%;
}

.plus-square-line {
  opacity: 0;
  width: 1%;
}

.plus-square {
  font-size: 14px;
  margin-right: 1px;
  background-color: #0000FF;
  border-radius: 20%;
}
