@import '~antd/dist/reset.css';

.ant-comment-inner {
  padding: 10px !important;
}

#comment .ant-form-item {
  margin-bottom: 10px !important;
}

.ant-comment-nested {
  margin-right: 10px !important;
}

.commentCard {
  padding-left: 10px;
  background-color: #F5F8F7;
  white-space: pre-line;
  width: 100%;
}

.parentComment {
  padding-top: 0px;
  border: 1px solid #389e0d;
  border-radius: 5px;
}
